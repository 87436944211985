const config = {
    useEmulators: !!process.env.REACT_APP_EMULATORS,
    functions: {
        testConnection: 'https://testconnection-rmvn6q4i6a-ew.a.run.app',
        refetchVehicleStatus: 'https://refetchvehiclestatus-rmvn6q4i6a-ew.a.run.app',
        executeIOTAction: 'https://executeiotaction-rmvn6q4i6a-ew.a.run.app',
        oauthCodeExchange: 'https://oauthcodeexchange-rmvn6q4i6a-ew.a.run.app',
    }
}

if (config.useEmulators) {
    config.functions = {
        testConnection: 'http://127.0.0.1:5001/zap-zenith/europe-west1/testConnection',
        refetchVehicleStatus: 'http://127.0.0.1:5001/zap-zenith/europe-west1/refetchVehicleStatus',
        executeIOTAction: 'http://127.0.0.1:5001/zap-zenith/europe-west1/executeIOTAction',
        oauthCodeExchange: 'http://127.0.0.1:5001/zap-zenith/europe-west1/oauthCodeExchange',
    }
}

export default config;