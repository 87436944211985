import { FC, useEffect, useMemo, useState } from 'react'
import { Device, DeviceStatus } from '../models/shared/Device'
import { useFirestore } from 'reactfire';
import { collection, onSnapshot } from 'firebase/firestore';
import { fromFirebaseDocs } from '../utils/shared/firebase';
import { Card, CardHeader, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { texts } from '../utils/texts';
import { executeIOTAction } from '../utils/api';
import { COLLECTIONS } from '../utils/shared/constants';
import { LightCardBody } from './devices/LightCardBody';
import { SwitchCardBody } from './devices/SwitchCardBody';
import { AwningCardBody } from './devices/AwningCardBody';

interface IProps { }

/**
* @author
* @function @HomeDevices
**/

export const HomeDevices: FC<IProps> = (props) => {
    const [devices, setDevices] = useState<Device[]>([])
    const firestore = useFirestore();

    useEffect(() => {
        const col = collection(firestore, COLLECTIONS.DEVICES);

        return onSnapshot(col, snapshot => {
            const data = fromFirebaseDocs<Device>(snapshot.docs);
            data.sort((a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name));

            setDevices(data);
        });
    }, [firestore]);



    const toggleDevice = async (device: Device) => {
        try {
            const newDevices = devices.map(d => {
                if (d.id === device.id) {
                    return {
                        ...d,
                        state: {
                            ...d.state,
                            status: (d.state.status === 'on' ? 'off' : 'on') as DeviceStatus,
                        }
                    };
                }
                return d;
            });
            setDevices(newDevices);

            await executeIOTAction(device.id || '', "toggle");
            toast.info(texts.actionScheduled);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    };



    const updateDevice = (device: Device) => {
        const newDevices = devices.map(d => {
            if (d.id === device.id) {
                return device;
            }
            return d;
        });
        setDevices(newDevices);
    }


    const renderBody = (device: Device) => {
        if (['light', 'light-no-color'].includes(device.type)) {
            return <LightCardBody device={device} updateDevice={updateDevice} />
        }

        if (device.type === 'awning') {
            return <AwningCardBody device={device} />
        }

        return <SwitchCardBody device={device} />
    };

    const orderedDevices = useMemo(() => devices.sort((a, b) => a.order! - b.order!), [devices]);

    return (
        <Row>
            {orderedDevices.map(device => {
                const enabled = device.state.status === 'on';
                const hasToggle = device.type !== 'awning';
                return <Col sm={6} className='my-2' key={device.id}>
                    <Card>
                        <CardHeader className="d-flex justify-content-between">
                            {device.name}

                            {hasToggle && <Form.Check type='switch' checked={enabled} onChange={() => toggleDevice(device)} />}
                        </CardHeader>
                        {renderBody(device)}
                    </Card>
                </Col>
            })}
        </Row >
    )
}
