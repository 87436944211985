import { FC } from 'react'
import { Device } from '../../models/shared/Device'
import { Button, CardBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { executeIOTAction } from '../../utils/api';
import { JobAction } from '../../models/shared/Job';

interface IProps {
    device: Device
}


export const AwningCardBody: FC<IProps> = (props) => {
    const { device } = props;

    const runOperation = async (action: JobAction) => {
        try {

            await executeIOTAction(device.id || '', action);
            toast.success('Command send');
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    };




    return <CardBody>
        <div className='d-flex gap'>
            <Button variant='outline-primary' onClick={() => runOperation('up')}>
                <i className="bi bi-arrow-up" />
            </Button>
            <Button variant='outline-primary' onClick={() => runOperation('stop')}>
                <i className="bi bi-stop" />
            </Button>
            <Button variant='outline-primary' onClick={() => runOperation('down')}>
                <i className="bi bi-arrow-down" />
            </Button>

        </div>
    </CardBody>
}


