import { FC } from 'react'
import { useSigninCheck } from 'reactfire';
import { texts } from '../utils/texts';
import { Button } from 'react-bootstrap';
import { HomeDevices } from '../components/HomeDevices';

interface IProps { }

/**
* @author
* @function @HomePage
**/

export const HomePage: FC<IProps> = (props) => {
    const { data: signedInResult } = useSigninCheck({ requiredClaims: { homeAdmin: true } });

    if (!signedInResult?.hasRequiredClaims) {
        return <div>
            <h4>{texts.homeNotEnabled}</h4>
            <a href='mailto:hello@beaver.codes?subject=I%20would%20like%20to%20enable%20home'>
                <Button>{texts.enableHome}</Button>
            </a>
        </div>
    }

    return (
        <>
            <div>
                <h3>{texts.homeAutomation}</h3>

                <p className='text-muted'>{texts.homeAutomationMotto}</p>
            </div>
            <HomeDevices />
        </>
    )
}
