import { Connection } from "../models/shared/Connection";
import { JobAction } from "../models/shared/Job";
import { Vehicle } from "../models/shared/Vehicle";
import config from "./config"

export interface TestConnectionResult {
    connection: Connection,
    vehicle: Vehicle,
}

export const testConnection = async (email: string, password: string, pin: string) => {
    const response = await fetch(
        config.functions.testConnection,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password, pin })
        }

    );
    const data = await response.json();

    if (!data.success) {
        throw new Error('Failed to get connection');
    }
    return { connection: data.connection, vehicle: data.vehicle };
}

export const refetchVehicleStatus = async (uid: string, connectionId: string) => {
    const response = await fetch(
        config.functions.refetchVehicleStatus,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ uid, connectionId })
        }

    );

    if (response.status !== 200) {
        throw new Error('Failed to update status ' + await response.text());
    }
}

export const executeIOTAction = async (deviceId: string, action: JobAction, params: Object = {}) => {
    const response = await fetch(
        config.functions.executeIOTAction,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ deviceId, action, params })
        }

    );
    const data = await response.json();

    if (!data.success) {
        throw new Error('Failed to execute action');
    }
}

export const oauthCodeExchange = async (token: string, code: string, redirectUrl: string) => {
    const response = await fetch(
        config.functions.oauthCodeExchange,
        {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code, redirectUrl })
        }
    );
    if (response.status !== 200) {
        const stringBody = await response.text();
        throw new Error('Failed to exchange code: ' + stringBody);
    }
}

export const OauthCallbackUrl = `${window.location.origin}/tesla`;

