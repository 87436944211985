import React, { FC } from 'react'

interface IProps {
    state: number
}

/**
* @author
* @function @Battery
**/

export const Battery: FC<IProps> = (props) => {
    let icon = 'bi-battery-full';
    if (props.state < 66) {
        icon = 'bi-battery-half'
    } else if (props.state < 33) {
        icon = 'bi-battery'
    }
    return (
        <div className='center' style={{ fontSize: 'xxx-large' }}>

            <i className={`bi ${icon} me-3`} />
            <span style={{ fontSize: 'xx-large' }} >{props.state}%</span>
        </div>
    )
}
