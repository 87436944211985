import { useEffect, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import useQueryParam from '../hooks/useQueryParams'
import { useAuth } from 'reactfire';
import { OauthCallbackUrl, oauthCodeExchange } from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import { AdvancedButton } from '../components/ButtonProcessing';

interface Props { }

function OAuthPage(props: Props) {
    const { code } = useQueryParam();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        const asyncEffect = async () => {
            try {
                const token = await currentUser?.getIdToken();
                await oauthCodeExchange(token + '', code + '', OauthCallbackUrl)
                navigate('/');
            } catch (e) {
                const msg = (e as any).message || e;
                console.error('something went wrong', e);

                if (msg.includes('No vehicles found')) {
                    setError('Account has no vehicles')
                } else {
                    setError('Something went wrong')
                }
            }
        }

        asyncEffect();
    }, [code, currentUser, navigate])

    if (error) {
        return <div>
            <Alert variant="danger">
                {error}
            </Alert>
            <Link to="/"><AdvancedButton variant='secondary' icon='bi-arrow-left'>Go back</AdvancedButton></Link>
        </div>
    }

    return <Spinner />
}

export default OAuthPage
