import { FC, useState } from 'react'
import { Device, DeviceState, DeviceStatus } from '../../models/shared/Device'
import { useFirestore } from 'reactfire';
import { doc, setDoc } from 'firebase/firestore';
import { Button, CardBody, Form, FormControl, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { texts } from '../../utils/texts';
import { COLLECTIONS } from '../../utils/shared/constants';
import { useForm } from 'react-hook-form';
import { convertIntervalToLocalTime, convertTimeToUTC } from '../../utils/shared/format';

interface IProps {
    device: Device
}

interface Inputs {
    start: string;
    end: string;
}

export const SwitchCardBody: FC<IProps> = (props) => {
    const { device } = props;
    const firestore = useFirestore();
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    const updateDeviceStatus = async (deviceId: string, newState: Partial<DeviceState>) => {
        try {
            await setDoc(doc(firestore, COLLECTIONS.DEVICES, deviceId), { state: newState }, { merge: true });
            toast.info(texts.updated);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    };

    const handleNewInterval = async (data: Inputs) => {

        const newIntervals = [...device.state.scheduleOffIntervals || []]
        const newUTCInterval = {
            start: convertTimeToUTC(data.start),
            end: convertTimeToUTC(data.end),
        }
        newIntervals.push(newUTCInterval);

        newIntervals.sort((a, b) => a.start.localeCompare(b.start));

        await updateDeviceStatus(device.id || '', {
            scheduleOffIntervals: newIntervals,
        });

        setShowScheduleModal(false);
    }
    const renderSchedulte = () => {
        const intervals: FinalIntervals[] = [];
        const scheduleOffUTCIntervals = device.state.scheduleOffIntervals || [];
        const scheduleOffIntervals = scheduleOffUTCIntervals.map(convertIntervalToLocalTime);

        for (let index = 0; index < scheduleOffIntervals.length; index++) {
            const offInterval = scheduleOffIntervals[index];
            if (intervals.length === 0) {
                intervals.push({
                    start: '00:00',
                    end: offInterval.start,
                    state: 'on',
                });
            }

            intervals.push({
                start: offInterval.start,
                end: offInterval.end,
                state: 'off',
                originalIndex: index,
            });
        }
        intervals.push({
            start: scheduleOffIntervals[scheduleOffIntervals.length - 1]?.end || '00:00',
            end: '23:59',
            state: 'on',
        });

        return <ul>
            {intervals.map((interval, index) => <li key={index}>
                <span className={interval.state === 'on' ? 'text-success' : 'text-danger'}>{interval.state}</span>:
                <span className='mx-2'>{interval.start} - {interval.end}</span>
                {interval.state === 'off' && <Button variant='outline-danger' size='sm' onClick={() => {
                    const newIntervals = [...scheduleOffUTCIntervals];
                    newIntervals.splice(interval.originalIndex || 0, 1);
                    updateDeviceStatus(device.id || '', {
                        scheduleOffIntervals: newIntervals,
                    });
                }}>X</Button>}
            </li>)}
        </ul>
    }


    const usingSchedule = !!device.state.usingSchedule;
    return <CardBody>
        <div className='d-flex justify-content-between'>
            <p className='text-muted m-0'>Schedule</p>
            <Form.Check type='switch'
                checked={usingSchedule}
                onChange={() => updateDeviceStatus(device.id || '', {
                    usingSchedule: !usingSchedule,
                })} />
        </div>

        {usingSchedule && <>
            {renderSchedulte()}
            <Button variant='outline-primary' onClick={() => setShowScheduleModal(true)}>
                + <i className="bi bi-lightbulb-off" />
            </Button>
        </>}

        {showScheduleModal && <Modal show={true} onHide={() => setShowScheduleModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Turn-OFF time</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(handleNewInterval)}>
                <Modal.Body>
                    <FormControl type='time' {...register('start', { required: true })} isInvalid={!!errors.start} />
                    <FormControl type='time' {...register('end', { required: true })} isInvalid={!!errors.end} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-secondary' onClick={() => setShowScheduleModal(false)}>{texts.cancel}</Button>
                    <Button type='submit' variant='primary'>{texts.save}</Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </CardBody>
}

interface FinalIntervals {
    start: string;
    end: string;
    state: DeviceStatus;
    originalIndex?: number;
}


