import { FC, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { Connection } from '../models/shared/Connection';
import { useUser } from 'reactfire';
import { COLLECTIONS } from '../utils/shared/constants';
import { texts } from '../utils/texts';
import { ConnectionNewModal } from '../components/ConnectionNewModal';
import { StatusView } from '../components/StatusView';
import { useFirebaseDoc, useFirebaseQuery, useFirebaseQueryOptions } from '../hooks/firebaseHooks';
import { Vehicle } from '../models/shared/Vehicle';
import { Job } from '../models/shared/Job';
import { orderBy, where } from 'firebase/firestore';

interface IProps { }
const decisionDate = new Date();
decisionDate.setDate(decisionDate.getDate() - 1);

export const VehiclePage: FC<IProps> = (props) => {
    const { data: user } = useUser();
    const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
    const [userConnections, , loadedConnections] = useFirebaseQuery<Connection>(`${COLLECTIONS.USERS}/${user?.uid}/${COLLECTIONS.CONNECTIONS}`);
    const connection = userConnections[0] || null;
    const [vehicle, setVehicle, loadedVehicle] = useFirebaseDoc<Vehicle>(`${COLLECTIONS.VEHICLES}/${connection?.vehicleId}`);
    const [jobs] = useFirebaseQueryOptions<Job>(COLLECTIONS.JOBS, {
        queryConstraints: [
            where('vehicleId', '==', connection?.vehicleId || ''),
            where('scheduledAt', '>', decisionDate),
            orderBy('scheduledAt', 'asc'),
        ],
        skip: !connection?.vehicleId
    }
    );

    if (!loadedConnections || !loadedVehicle) {
        return <div className='center'><Spinner /></div>
    }

    if (!connection || !vehicle) {
        return <div>
            <h4>{texts.noCarConnected}</h4>
            <Button onClick={() => setShowNewConnectionModal(true)}>{texts.connectCar}</Button>

            <ConnectionNewModal show={showNewConnectionModal}
                handleClose={() => setShowNewConnectionModal(false)}
                handleSuccess={() => setShowNewConnectionModal(false)}
            />
        </div>
    }

    return <StatusView connection={connection} vehicle={vehicle} setVehicle={setVehicle} jobs={jobs} />
}

