export const texts = {
    brand: "⚡ Zap Zenith",

    defaultError: '💥 Something went boom.',
    defaultSuccess: '🎉 Success!',

    delete: 'Delete',
    edit: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
    add: 'Add',
    view: 'View',
    confirm: 'Confirm',
    create: 'Create',
    confirmation: 'Confirmation',
    thisActionCannotBeUndone: 'This action cannot be undone.',
    attendees: 'Attendees',
    name: 'Name',
    account: 'Account',
    logout: 'Logout',
    menu: 'Menu',
    car: 'Car',
    home: 'Home',
    updated: 'Updated',

    // Login
    emailSentTo: 'Email sent to',
    checkSpamFolder: 'If you don\'t see it, check your spam folder.',
    emailAddress: 'Email Address',
    enterEmail: 'Enter Email',
    login: 'Login',

    // Car
    noCarConnected: 'No car connected',
    connectCar: 'Connect Car',
    connectCarDescription: 'To connect your car, you will need credentials used in your car\'s mobile app.',
    email: 'Email',
    password: 'Password',
    pin: 'Pin',
    testConnection: 'Test Connection',
    vehicleFound: 'Vehicle Found',
    connect: 'Connect',

    // Home
    homeNotEnabled: 'Home not enabled',
    enableHome: 'Enable Home',
    homeAutomation: 'Home Automation',
    homeAutomationMotto: 'Because Getting up is Overrated',

    // Status
    refetch: 'Refetch',
    batteryHealth: 'Battery Health',
    batteryCharge: 'Battery Charge',
    mainBattery: 'Main Battery',
    twelveVBattery: '12V Battery',
    updatedAt: 'Updated At',
    charging: 'Charging',
    pluggedIn: 'Plugged in',
    chargedAt: 'Charged At',
    range: 'Range',
    distance: 'Distance',
    na: 'N/A',

    // Job Activity
    actions: 'Actions',
    start: 'Start',
    stop: 'Stop',
    schedule: 'Schedule',
    actionScheduled: 'Action Scheduled',

    // Scheduled Actions
    scheduledActions: 'Scheduled Actions',
    time: 'Time',
    action: 'Action',
    status: 'Status',
    scheduleCharging: 'Schedule Charging',


}
