import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { buildPlanFromJobs, getDateFromHourKey, getHourKey, getHourKeys } from '../utils/shared/calculations';
import { formatAmount, formatDateTime, zeroPad } from '../utils/shared/format';
import { PRIMARY_COLOR } from '../utils/shared/constants';
import { ChargePlan } from '../models/shared/ChargePlan';
import { Job } from '../models/shared/Job';
import 'rc-slider/assets/index.css';

interface Props {
    prices: Record<string, number>
    plan: ChargePlan
    jobs: Job[]
    isCharging: boolean
}

function CustomTooltip(props: any) {
    const payload = props.payload?.[0]?.payload;
    if (props.active && payload) {
        return <div className='bg-white p-3 border rounded'>
            <div>
                {formatDateTime(getDateFromHourKey(payload.name))}
            </div>
            <div>
                {formatAmount(payload.price || 0)} kr
            </div>
            <div>
                {payload?.scheduledValue ? <strong>Will charge</strong> : <>Will not charge</>}
            </div>
        </div>
    }

    return null;
}


function ChargingPlanChart(props: Props) {
    const start = new Date();
    const end = new Date(start);
    end.setDate(start.getDate() + 1);

    const times = getHourKeys(getHourKey(start), getHourKey(end));
    const scheduledPlan = buildPlanFromJobs(props.isCharging, props.jobs, times);

    const data = times.map(time => {
        const hourDate = getDateFromHourKey(time);
        const hour = zeroPad(hourDate.getHours());

        const hourPlan = props.plan.planPerHour[time];
        const hourScheduledPlan = scheduledPlan.planPerHour[time];

        return {
            price: props.prices[time] !== undefined ? props.prices[time] / 100 : undefined,
            hour,
            name: time,
            value: hourPlan?.consumption || 0,
            scheduledValue: hourScheduledPlan?.consumption || 0,
        }
    })

    return <ResponsiveContainer width="100%" height={180}>
        <ComposedChart data={data}>
            <XAxis dataKey="hour" />
            <YAxis yAxisId="right" orientation="right" unit={` kWh`} />
            <YAxis unit={` kr`} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="price" stroke={PRIMARY_COLOR} />
            <Bar dataKey="value" yAxisId="right" fill={PRIMARY_COLOR + '30'} />
            <Bar dataKey="scheduledValue" yAxisId="right" fill={PRIMARY_COLOR} />
        </ComposedChart>
    </ResponsiveContainer>
}


export default ChargingPlanChart
