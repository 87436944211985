import { FC, useState } from 'react'
import { Device, ScheduleRecuringTime } from '../../models/shared/Device'
import { Button, CardBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { texts } from '../../utils/texts';
import { executeIOTAction } from '../../utils/api';
import { Job, JobAction } from '../../models/shared/Job';
import * as Slider from '@radix-ui/react-slider';
import ScheduleLightModal from '../modals/ScheduleLightModal';
import { useFirestore, useUser } from 'reactfire';
import { addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where, } from 'firebase/firestore';
import { COLLECTIONS } from '../../utils/shared/constants';
import { findFirstNextRecuringDate } from '../../utils/shared/calculations';
import { fromFirebaseDocs } from '../../utils/shared/firebase';

interface IProps {
    device: Device
    updateDevice: (device: Device) => void
}

/**
* @author
* @function @HomeDevices
**/

export const LightCardBody: FC<IProps> = (props) => {
    const { device } = props;
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const firestore = useFirestore();
    const { data: user } = useUser();

    const togglePartyMode = async (device: Device) => {
        const newPartyMode = !device.state.partyMode;
        try {
            const newDevice = {
                ...device,
                state: {
                    ...device.state,
                    partyMode: newPartyMode,
                }
            }
            props.updateDevice(newDevice);

            await executeIOTAction(device.id || '', "partyMode", { on: newPartyMode });
            toast.info(texts.actionScheduled);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    };

    const scheduleAction = async (device: Device, action: JobAction, params: any = {}) => {
        try {
            await executeIOTAction(device.id || '', action, params);
            toast.info(texts.actionScheduled);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    }

    const handleNewScheduleTimes = async (times: ScheduleRecuringTime[]) => {
        setShowScheduleModal(false);

        const newDevice = {
            ...device,
            state: {
                ...device.state,
                scheduleRecuringTimes: times
            }
        }

        const q = query(collection(firestore, COLLECTIONS.JOBS), where('deviceId', '==', device.id), where('status', '==', 'scheduled'));
        const scheduledJobsSnap = await getDocs(q);
        const scheduledJobs = fromFirebaseDocs<Job>(scheduledJobsSnap.docs);
        for (const job of scheduledJobs) {
            await updateDoc(doc(firestore, COLLECTIONS.JOBS, job.id || ''), { status: 'cancelled' });
        }

        const nextDate = findFirstNextRecuringDate(times);
        if (nextDate) {
            const newJob: Job = {
                action: 'turnOn',
                connectionId: '',
                createdAt: new Date(),
                deviceId: device.id || '',
                scheduledAt: nextDate,
                status: 'scheduled',
                uid: user?.uid || '',
                updatedAt: new Date(),
                isRecuring: true,

            }
            await addDoc(collection(firestore, COLLECTIONS.JOBS), newJob);
        }

        await setDoc(doc(firestore, COLLECTIONS.DEVICES, device.id || ''), { state: newDevice.state }, { merge: true });
        toast.success(texts.updated);
        props.updateDevice(newDevice);
    }

    const supportsColors = device.type === 'light';
    let scheduleInfo = 0 + '';
    if (device.state.scheduleRecuringTimes) {
        scheduleInfo = device.state.scheduleRecuringTimes.length + ''
        const next = findFirstNextRecuringDate(device.state.scheduleRecuringTimes);
        if (next) {
            scheduleInfo += ' - ' + next.toLocaleString();
        }
    }
    return <CardBody>
        {supportsColors && <>
            <Button variant='outline-primary' className='mr-2' onClick={() => scheduleAction(device, 'setDay')}>
                <i className="bi bi-brightness-high" />
            </Button>
            <Button variant='outline-primary' className='mr-2' onClick={() => scheduleAction(device, 'setEvening')}>
                <i className="bi bi-brightness-low" />
            </Button>
            <Button variant='outline-primary ms-3' className='mr-2' onClick={() => scheduleAction(device, 'random')}>
                <i className="bi bi-shuffle" />
            </Button>
            <Button variant={`${device.state.partyMode ? 'primary' : 'outline-primary'} ms-3`} className='mr-2' onClick={() => togglePartyMode(device)}>
                <i className="bi bi-cake" />
            </Button>
        </>}

        <p className='text-muted m-0 mt-3'>Brightness</p>
        <Slider.Root className="SliderRoot" defaultValue={[device.state.brightness || 0]} max={254} step={1} onValueCommit={values => scheduleAction(device, 'setBrightness', { value: values[0] })}>
            <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
            </Slider.Track>
            <Slider.Thumb className="SliderThumb" aria-label="Volume" />
        </Slider.Root>

        <p className='text-muted m-0 mt-3'>Temperature</p>
        <Slider.Root className="SliderRoot" defaultValue={[device.state.temperature || 0]} max={454} step={1} onValueCommit={values => scheduleAction(device, 'setTemperature', { value: values[0] })}>
            <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
            </Slider.Track>
            <Slider.Thumb className="SliderThumb" aria-label="Volume" />
        </Slider.Root>

        <button className='btn mt-3' onClick={() => setShowScheduleModal(true)}>Schedule ({scheduleInfo})</button>

        <ScheduleLightModal show={showScheduleModal} onClose={() => setShowScheduleModal(false)}
            scheduleTmes={device.state.scheduleRecuringTimes || []}
            onSave={handleNewScheduleTimes}
        />
    </CardBody>

}

