import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, FormControl, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Form } from 'react-router-dom'
import { ScheduleRecuringTime } from '../../models/shared/Device'
import { getDateFromTimeString } from '../../utils/shared/calculations'
import { DAYS_OF_WEEK } from '../../utils/shared/constants'

interface Props {
    show: boolean
    onClose: () => void
    scheduleTmes: ScheduleRecuringTime[],
    onSave: (times: ScheduleRecuringTime[]) => void
}

interface Inputs {
    time: string
}

function ScheduleLightModal(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()
    const [times, setTimes] = useState<ScheduleRecuringTime[]>(props.scheduleTmes)
    const [days, setDays] = useState<string[]>(DAYS_OF_WEEK.map(day => day.code))

    useEffect(() => {
        setTimes(props.scheduleTmes)
    }, [props.scheduleTmes])

    const onSubmit = (data: Inputs) => {
        const [hours, minutes] = data.time.split(':')
        const date = new Date()
        date.setHours(parseInt(hours))
        date.setMinutes(parseInt(minutes))
        const timeString = `${date.getUTCHours()}:${date.getUTCMinutes()}`

        const dayCodes = DAYS_OF_WEEK.filter(day => days.includes(day.code)).map(day => day.code)

        const newTime: ScheduleRecuringTime = {
            timeString,
            dayCodes
        }

        const newTimes = [...times, newTime]

        setTimes(newTimes)
    }

    const deleteTime = (index: number) => {
        const newTimes = [...times]
        newTimes.splice(index, 1)
        setTimes(newTimes)
    }

    const handleSave = () => {
        props.onSave(times)
    }

    return <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column gap'>
                <h6>New Lights Up</h6>
                <FormControl type='time' {...register('time', { required: true })} isInvalid={!!errors.time} />

                <ButtonGroup>

                    {DAYS_OF_WEEK.map(day => {
                        const checked = days.includes(day.code)
                        return <Button key={day.value} variant={checked ? 'primary' : 'outline-primary'} onClick={() => {
                            if (checked) {
                                setDays(days.filter(d => d !== day.code))
                            } else {
                                setDays([...days, day.code])
                            }
                        }
                        }>{day.code}</Button>

                    })}
                </ButtonGroup>

                <Button type='submit' variant='outline-primary' >Add</Button>
            </Form>

            <hr />
            <h6>Current Schedule</h6>

            <ul className='p-0'>
                {times.map((time, index) => <li key={index} className='d-flex align-items-center gap'>
                    <div>{getDateFromTimeString(time.timeString).toLocaleTimeString()}</div>
                    <div>                    {'<' + time.dayCodes?.join(', ') + '>'}                    </div>
                    <Button className='ms-3' variant='outline-danger' size='sm' onClick={() => deleteTime(index)}>X</Button>
                </li>)}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='outline-secondary' onClick={props.onClose}>cancel</Button>
            <Button onClick={handleSave} variant='primary'>Save</Button>
        </Modal.Footer>
    </Modal>
}

export default ScheduleLightModal
