import React, { FC } from 'react'

interface IProps {
    state: boolean
}

/**
* @author
* @function @TrueFalseIcon
**/

export const TrueFalseIcon: FC<IProps> = (props) => {
    if (props.state) {
        return <i className='bi bi-check-circle-fill text-success' />
    }
    return <i className='bi bi-x-circle-fill text-danger' />
}
