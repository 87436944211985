import { FC, useState } from 'react'
import { Connection } from '../models/shared/Connection'
import { AdvancedButton } from './ButtonProcessing'
import { texts } from '../utils/texts'
import { refetchVehicleStatus } from '../utils/api'
import { useUser } from 'reactfire'
import { toast } from 'react-toastify'
import { Status } from '../models/shared/Status'
import { limit, orderBy } from 'firebase/firestore'
import { COLLECTIONS } from '../utils/shared/constants'
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { Battery } from './Battery'
import { TrueFalseIcon } from './TrueFalseIcon'
import { JobList } from './JobList'
import { ChargingActions } from './ChargingActions'
import { formatDuration } from '../utils/shared/format'
import { Job } from '../models/shared/Job'
import { useFirebaseQuery } from '../hooks/firebaseHooks'
import { Vehicle } from '../models/shared/Vehicle'

interface IProps {
    connection: Connection
    vehicle: Vehicle
    setVehicle: (Vehicle: Vehicle) => void
    jobs: Job[]
}

/**
* @author
* @function @Status
**/

export const StatusView: FC<IProps> = (props) => {
    const [processing, setProcessing] = useState(false)
    const { data: user } = useUser();
    const vehicleId = props.connection.vehicleId;
    const [statuses] = useFirebaseQuery<Status>(`${COLLECTIONS.VEHICLES}/${vehicleId}/${COLLECTIONS.STATUSES}`, orderBy('createdAt', 'desc'), limit(1));
    const latestStatus = statuses[0] || null;
    const vehicle = props.vehicle;

    const refetch = async () => {
        setProcessing(true);

        try {
            await refetchVehicleStatus(user?.uid || '', props.connection.id + '');
        } catch (e) {
            toast.error((e as Error).message);
        }

        setProcessing(false);
    }

    const remainingChargeTime = latestStatus?.estimatedChargeDurationMinutes;
    return (
        <div>
            <div className='d-flex justify-content-between align-items-start'>
                <div>
                    <h3>{vehicle?.nickname}</h3>

                    <p className='text-muted'>{vehicle?.vin}</p>
                </div>
                <AdvancedButton processing={processing} variant='secondary' icon='bi-arrow-repeat' onClick={refetch}>
                    {texts.refetch}
                </AdvancedButton>
            </div>

            {!!latestStatus && <>
                <div className='row'>
                    <Col sm={6} className='my-2'>
                        <Card>
                            <CardHeader>{texts.mainBattery}</CardHeader>
                            <CardBody>
                                <Battery state={latestStatus.batteryCharge} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6} className='my-2'>
                        <Card>
                            <CardHeader>Status</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>{texts.charging}</Col>
                                    <Col><TrueFalseIcon state={latestStatus.isCharging} /></Col>
                                </Row>
                                <Row>
                                    <Col>{texts.pluggedIn}</Col>
                                    <Col><TrueFalseIcon state={!!latestStatus.isPluggedIn} /></Col>
                                </Row>
                                <Row>
                                    <Col>{texts.chargedAt}</Col>
                                    <Col>{formatDuration(remainingChargeTime || 0)}</Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </div>
                <p className='text-muted ' style={{ fontSize: 'small' }}>{texts.updatedAt}: {latestStatus.createdAt.toLocaleString()}</p>
            </>}

            <hr />
            <ChargingActions connection={props.connection} vehicle={props.vehicle} setVehicle={props.setVehicle} latestStatus={latestStatus} jobs={props.jobs} />

            <hr />
            <JobList jobs={props.jobs} />
        </div>
    )
}
