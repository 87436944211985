import React, { FC, useState } from 'react'
import { Button, FormControl, FormLabel, Modal } from 'react-bootstrap'
import { texts } from '../utils/texts'
import { Form } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { AdvancedButton } from './ButtonProcessing'
import { TestConnectionResult, OauthCallbackUrl, testConnection } from '../utils/api'
import { toast } from 'react-toastify'
import { useFirestore, useUser } from 'reactfire'
import { doc, setDoc } from 'firebase/firestore'
import { COLLECTIONS, DEFAULT_BATTERY_KWH, EMAIL, MAILTO } from '../utils/shared/constants'
import { ConnectionType } from '../models/shared/Connection'
import { useFirebaseDoc } from '../hooks/firebaseHooks'
import { PublicConfig } from '../models/shared/PublicConfig'

interface IProps {
    show: boolean
    handleClose: () => void
    handleSuccess: () => void
}

/**
* @author
* @function @ConnectionNewModal
**/

interface Inputs {
    email: string
    password: string
    pin: string
}


const SCOPES = 'offline_access vehicle_device_data vehicle_charging_cmds vehicle_cmds';
export const ConnectionNewModal: FC<IProps> = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()
    const [processing, setProcessing] = React.useState(false);
    const [testResult, setTestResult] = React.useState<TestConnectionResult | null>(null);
    const [type, setType] = useState<ConnectionType | null>(null);
    const [config] = useFirebaseDoc<PublicConfig>(`${COLLECTIONS.CONFIG}/public`);
    const { data: user } = useUser();
    const firestore = useFirestore();

    const teslaAuthLink = `https://auth.tesla.com/oauth2/v3/authorize?client_id=${config?.teslaClientId}&redirect_uri=${OauthCallbackUrl}&response_type=code&scope=${SCOPES}&state=tesla`

    const handleClose = () => {
        setType(null);
        setTestResult(null);
        props.handleClose();
    }

    const handleSave = async (data: Inputs) => {
        setProcessing(true);

        try {
            const result = await testConnection(data.email, data.password, data.pin);
            setTestResult(result);
        } catch (e) {
            console.error(e);
            toast.error((e as Error).message);
        }


        setProcessing(false);
    }

    const handleConnect = async () => {
        if (!testResult || !user) {
            return;
        }

        setProcessing(true);


        await setDoc(
            doc(firestore, COLLECTIONS.USERS, user.uid, COLLECTIONS.CONNECTIONS, testResult.connection.vehicleId),
            testResult.connection
        );
        const newVehicle = {
            batteryKWH: DEFAULT_BATTERY_KWH,
            ...testResult.vehicle,
        }
        await setDoc(doc(firestore, COLLECTIONS.VEHICLES, testResult.connection.vehicleId), newVehicle);

        setProcessing(false);
        props.handleSuccess();
    }

    const renderBody = () => {
        if (testResult) {
            return <>
                <Modal.Body>
                    <p>{texts.vehicleFound}</p>
                    <div className='mt-3'>
                        <h5>{testResult.vehicle.nickname}</h5>
                        <p className='text-muted'>{testResult.vehicle.vin}</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <AdvancedButton variant="primary" type='submit' processing={processing} onClick={handleConnect}>
                        {texts.connect}
                    </AdvancedButton>
                </Modal.Footer>
            </>
        }

        if (type === 'Hyundai') {
            return <Form onSubmit={handleSubmit(handleSave)}>
                <Modal.Body>
                    <p>Enter your <strong>Bluelink</strong> credentials</p>
                    <FormLabel>{texts.email}</FormLabel>
                    <FormControl type="email" {...register('email', { required: true })} isInvalid={!!errors.email} />

                    <FormLabel>{texts.password}</FormLabel>
                    <FormControl type="password" {...register('password', { required: true })} isInvalid={!!errors.password} />

                    <FormLabel>{texts.pin}</FormLabel>
                    <FormControl type="password" {...register('pin', { required: true })} isInvalid={!!errors.pin} />
                </Modal.Body>
                <Modal.Footer>
                    <AdvancedButton variant="primary" type='submit' processing={processing}>
                        {texts.save}
                    </AdvancedButton>
                </Modal.Footer>
            </Form>
        }

        if (type === 'Other') {
            return <Modal.Body>
                <p>Sorry, we don't support this vehicle brand yet</p>
                <p>Please reach out to us <a href={MAILTO}>{EMAIL}</a> so we can fix that!</p>
            </Modal.Body>
        }

        return <Modal.Body>
            <p>Select your vehicle brand</p>
            <div className='d-flex flex-wrap'>
                <Button
                    onClick={() => setType('Hyundai')}
                    size='lg'
                    variant='outline-primary'
                    className='flex-1 m-3 p-4'>
                    Hyundai
                </Button>
                <div className='flex-1 m-3'>
                    <a href={teslaAuthLink} className=''>

                        <Button
                            onClick={() => setType('Tesla')}
                            size='lg'
                            variant='outline-primary'
                            className='w-100 p-4'>
                            Tesla
                        </Button>
                    </a>
                </div>
                <Button
                    onClick={() => setType('Other')}
                    size='lg'
                    variant='outline-primary'
                    className='flex-1 m-3 p-4'>
                    Other
                </Button>
            </div>
        </Modal.Body>
    }

    return (
        <Modal show={props.show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{texts.connectCar}</Modal.Title>
            </Modal.Header>
            {renderBody()}
        </Modal >
    )
}
